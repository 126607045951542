import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import ScrollIntoView from 'react-scroll-into-view'
import {getDefaultDatesUrl, getNeoDatesUrl,getToffDatesUrl} from "../../utils";
import {NavLink} from "react-router-dom";
import { Translation } from 'react-i18next';
import { format } from 'date-fns'
import { de } from 'date-fns/locale';
import { enGB } from 'date-fns/locale';
class HomeDefault extends Component {

    constructor(props) {
        super(props);
        this.state = {
            language: props.language,
            eventlist: [],
            highlights: [],
            image: null,
            text: null,
            total_chunks: 0,
            current_chunks: 0,
            chunkSize: 20,
            loading: true
        }
        /*        this.renderShowmore.bind(this);*/
        /*        this.handleClick = this.handleClick.bind(this);*/

    }

    getData() {
        fetch(getToffDatesUrl(), {
            mode: 'cors',
        })
            .then(response => response.json())
            .then(data => {
                /*                const chunkSize = this.state.chunkSize;
                                const result = [];
                                for (let i = 0; i < data.dates.length; i += chunkSize) {
                                    const chunk = data.dates.slice(i, i + chunkSize);
                                    result[i] = chunk;
                                }*/
                this.setState({
                    image: data.slider.image,
                    current_chunks: 0,
                    eventlist: data.dates.entries,
                    loading: false,
                    text: data.slider.text_DE,
                    text_DE: data.slider.text_DE,
                    text_EN: data.slider.text_EN,
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    componentDidMount() {
        this.getData();
    }
    // componentDidUpdate(prevProps) {
    //     console.log('componentDidUpdate');
    //     // this.getData();
    // }
    renderTime = function renderTime(time) {
        if (time !== null)
            return <>{time} Uhr</>;
        return null;
    }
    //Interner / externer Link //REACT_APP_HOST
    checkInternalLink(link){
        const host = process.env.REACT_APP_HOST.replace("https://", "");
        function isExternalLink() {
            const tmp = document.createElement('a');
            tmp.href = link;
            return tmp.host !== host;
        }
        return !isExternalLink();
    }
    /*    renderShowmore = function renderShowmore(show) {
            if (show)
                return <div className={'text-center mt-3  w-100 d-grid gap-2'}>
                    <button className={'btn btn-outline btn-outline-light border-3'}
                            onClick={this.handleClick}>+ Weitere anzeigen
                    </button>
                </div>;
            return null;
        }*/
    /*    renderDate = function renderDate(show) {
            if (show)
                return <div className={'text-center mt-3  w-100 d-grid gap-2'}>
                    <button className={'btn btn-outline btn-outline-light border-3'}
                            onClick={this.handleClick}>+ Weitere anzeigen
                    </button>
                </div>;
            return null;
        }*/
    /*    handleClick(param) {
            this.setState((state) => {
                if (state.current_chunks < state.total_chunks) {
                    const pusharray = state.eventlist.concat(state.allevents[(state.current_chunks + 1) * this.state.chunkSize]);
                    return {
                        current_chunks: state.current_chunks + 1,
                        eventlist: pusharray
                    }
                }
                return null;
            });
        }*/
    render() {
        return (
            <div className="eventlist-home">
                <div className={'hero bg-black d-flex d-flex-column'}>
                    <div className={'container mt-auto mb-auto'}>
                        <div className={'row'}>
{/*                            <div className={'offset-lg-8 col-lg-4 text-end'}>
                                TBD Schalter einbauen
                                <Link className={"d-inline-block"} to={this.props.language==='en'?'/en/neo':'/neo'}>
                                <img className={'neo-link'} width={180} alt={'NEO'} src={'/static/images/neo_siegel.png'} />
                                </Link>
                            </div>*/}
                            <div className={'col-12'}>
                                {/*<h1 className={'text-white fz-140 fw-normal main-heading mb-0'}><span className={'text-secondary'}>tanz</span><br className={'d-block d-lg-none'}/>OFFensive</h1>*/}
                                <h1 className={'text-white fz-140 fw-normal main-heading mb-0'}><img alt={'Keyvisual'} src={'/static/images/tanzoffensive_hannover_mini.svg'} /></h1>
                            </div>
                            <div className={'col-md-6 d-flex align-items-start flex-column pe-md-5'}>
                                <div className={'align-self-start '}>
                                    <p className={'text-uppercase fz-45 fw-bold text-secondary'}>Festival <span className={'text-white'}> | 9.5.–8.6.2025</span></p>
                                </div>
                                <ScrollIntoView selector="#program"
                                                className={'d-block align-self-center mb-auto mt-auto w-100 d-grid gap-2'}>
                                    <button
                                        className={'mt-5 btn btn-outline btn-outline-light border-3 rounded-pill'}>
                                        <Translation>
                                            {
                                                t => <>{t('festivalBtn')}</>
                                            }
                                        </Translation>
                                    </button>
                                </ScrollIntoView>
                            </div>
                        </div>
                    </div>
                </div>
                <section id={'program'} className={'bg-dark pb-5'}>
                    <article className={'container'}>

                        <h1 className={'h2 pb-5 section-heading'}>
                            <Translation>
                                {
                                    t => <>{t('festivalHead')}</>
                                }
                            </Translation>

                        </h1>
                        <div className={'row'}>
                            <div className={'col-lg-6 eventschedule'}>
                                {this.state.eventlist.map((event, index) => (
                                    <div key={'event-' + index} className={' event row'}>
                                        <div className={'col-12'}>
                                            <div className={'row'}>
                                                <div className={'col-md-5 col-xl-4 pb-2 pt-2 pb-2'}>
                                                    {<React.Fragment>
                                                        {event.moreDates.map((myDate, index) => {
                                                            return (
                                                                <span key={'date-'+index} className={'d-lg-block fw-bold me-3'}>
                                                                {(this.props.language==='de') ?
                                                                    <>
                                                                        {format(new Date(myDate.value.date+'T00:00:00.000Z'), 'EEEEEE dd.MM.yyyy',{
                                                                            locale: de
                                                                        })}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {format(new Date(myDate.value.date+'T00:00:00.000Z'), 'EEEEEE dd.MM.yyyy',
                                                                            {
                                                                                locale: enGB
                                                                            })}
                                                                    </>
                                                                }

                                                                </span>
                                                            );
                                                        })}
                                                    </React.Fragment>
                                                    }
                                                </div>
                                                <div className={'col-md-7 col-xl-8 pt-2 pb-2'}>
                                                    <p className={''}>
                                                        <NavLink
                                                            className={"title-link"}
                                                            to={(this.props.language==='de' ? ('/events/' + event._id) : ('/en/events/' + event._id))}
                                                        >
                                                            {event.title!==null && <>
                                                                <b>{(this.props.language==='de' ? event.title : event.title_EN)}</b><br />
                                                            </>}
                                                            {event.subtitle!==null && <>
                                                                <b>{(this.props.language==='de' ? event.subtitle : event.subtitle_EN)}</b><br />
                                                            </>}
                                                            {(this.props.language==='de' ? event.slogan : event.slogan_EN)}
                                                            <div>
                                                                <u>»&nbsp;
                                                                    <Translation>
                                                                        {
                                                                            t => <>{t('more')}</>
                                                                        }
                                                                    </Translation>
                                                                </u></div>

                                                        </NavLink>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'col-2 d-flex align-items-center'}>
                                  {/*          {this.checkInternalLink(event.link) === false ? (
                                                <a href={event.link}
                                                   className={`d-flex list-link-read-more ${event.link ? "extern" : ""}`}
                                                   title={event.title + ' anzeigen'}><span
                                                    className={'d-none'}>{event.title}</span></a>
                                            ) : (
                                                <NavLink
                                                    className={`d-flex list-link-read-more ${event.link ? "extern" : ""}`}
                                                    to={event.link}
                                                    title={event.title + ' anzeigen'}
                                                >
                                                    <span className={'d-none'}>{event.title}</span>
                                                </NavLink>
                                            )
                                            }*/}
                                        </div>
                                    </div>
                                ))}

                            </div>
                            <div className={'col-lg-6 pt-5 pt-lg-0 ps-lg-5'}>
                                <div className={'lead hyphenation'} dangerouslySetInnerHTML={{__html: this.state[(this.props.language==='de'?'text_DE':'text_EN')]}}></div>
                            </div>
                        </div>
                    </article>
                </section>
            </div>
        );
    }
}

export default HomeDefault;

