import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
i18n
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'de',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        // language resources
        resources: {
            de: {
                translation: {
                    Programm: "Programm",
                    Infos : "Infos ",
                    festivalBtn: "zum Festival-Programm",
                    festivalHead: "Festival-Programm",
                    Workshops : "Workshops ",
                    tanzLOKAL : "tanzLOKAL ",
                    Festivalinfos : "Festivalinfos ",
                    Contact : "Kontakt & Festivalteam ",
                    Admission : "Eintritt / VVK",
                    Arrival : "Anreise",
                    Organiser:'Veranstalter',
                    Sponsoredby:'Gefördert von',
                    Cooperation:'Kooperationspartner',
                    privacy:'Datenschutz',
                    imprint:'Impressum',
                    SponsorClaim:'Unterstützt durch das NATIONALE PERFORMANCENETZ Gastspielförderung Tanz, gefördert von der Beauftragten der Bundesregierung für Kultur und Medien, sowie den Kultur- und Kunstministerien der Länder.',
                    TrainingClaim:'Workshops',
                    more:'mehr',
                }
            },
            en: {
                translation: {
                    Programm: "Programme",
                    Infos : "Info",
                    festivalBtn: "to the festival programme",
                    festivalHead: "Festival programme",
                    Workshops : "Workshops ",
                    tanzLOKAL : "tanzLOKAL ",
                    Festivalinfos : "Festival info ",
                    Contact : "Contact & Festival Team ",
                    Admission : "Admission",
                    Arrival : "Arrival",
                    Organiser:'Organiser',
                    Sponsoredby:'Sponsored by',
                    Cooperation:'Cooperation partners',
                    privacy:'Privacy',
                    imprint:'Imprint',
                    SponsorClaim:'Supported by the NATIONAL PERFORMANCE NETWORK, funded by the Federal Government Commisioner for Culture and Media and the Miniteries of Culture and the Arts oft he German Länder',
                    TrainingClaim:'Professional trainings & workshops',
                    more:'more',
                }
            },
        }
    });

export default i18n;
